/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-throw-literal */
import { Auth0Client } from "@auth0/auth0-spa-js";
import axios, { AxiosRequestConfig } from "axios";
import { startCase } from "lodash";
import { apiConfig, auth0Config } from "../config";
import { Allergy } from "../types/allergy";
import { MedicalEncounter } from "../types/medicalEncounter";
import createFormData from "../utils/createFormData";
import { CodeableConcept } from "../types/codeableConcept";
import { getAuthHeader } from "./getAuthHeader";
import { MedicationAdministration } from "../types/medicationAdminstration";
import { MedicationStatement } from "../types/medicationStatement";
import { MedicationRequest } from "../types/medicationRequest";
import querySearchEndpoint from "../utils/querySearchEndpoint";
import * as Sentry from "@sentry/react";
import { MedicalConfiguration } from "../types/MedicalConfiguration";

const allergies = "allergy-intolerances";
const encounters = "encounters";
const conditions = "conditions";
const patientHistory = "patient-history";
const familyMemberHistory = "family-member-history";
const clinicalImpressions = "clinical-impressions";
const medicationStatements = "medication-statements";

export const medicalApiPaths = {
  medicationStatement: "medication-statements",
  compositions: "compositions",
  clinicalImpressions: "clinical-impressions",
  observations: "observations",
  procedures: "procedures",
  questionnaire: "questionnaire",
  questionnaireResponse: "questionnaire-response",
  conditions: "conditions",
  carePlan: "care-plan",
  flag: "flag",
  immunization: "immunization",
  encounters: "encounters",
  allergies: "allergy-intolerances",
  medicationRequest: "medication-requests",
  copilot: "copilots",
  medicalConfigurations: "medical-configurations",
} as const;

export type TMedicalApiPaths =
  (typeof medicalApiPaths)[keyof typeof medicalApiPaths];

class MedicalsApi {
  private auth0Client: Auth0Client | null = null;

  constructor() {
    this.auth0Client = new Auth0Client({
      redirect_uri: window.location.origin,
      ...auth0Config,
    });
  }

  async getAuthToken() {
    return await this.auth0Client?.getTokenSilently();
  }

  async findClinicalImpressions(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${clinicalImpressions}`,
      );
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error("Error when retrieving compositions " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  //TODO: Remove this method it's not used
  async getClinicalImpressionById(id: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/${clinicalImpressions}/${id}`,
        config,
      );
      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving clinical impression with id: ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  //TODO: Remove this method it's not used
  async saveClinicalImpression(formData: any, headers: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/${clinicalImpressions}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot Create New Clincal Impression",
        };
      }
      console.error(
        "Error when adding new clinical impression data " + JSON.stringify(e),
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getImmunizationHistory(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${medicalApiPaths.immunization}`,
      );

      return await querySearchEndpoint(
        url,
        { ...query, status: ["completed", "not-done"] },
        config,
      );
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Get Immunization History",
        };
      }

      console.error(
        `Error getting immunizations for patient. Error: ${JSON.stringify(e)}`,
      );

      throw new Error(`An error occured. Please try again.`);
    }
  }

  //TODO: Remove this method it's not used
  async updateClinicalImpression(
    id: string,
    formData: any,
    headers: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/${clinicalImpressions}/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot Update Clinical Impression",
        };
      }

      console.error(
        `Error updating clinical impression  with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );

      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getPatientHistory(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(`${apiConfig.medicalsServiceBase}/${patientHistory}`);
      const response = await querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error("Error when retrieving compositions " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateFamilyHistory(
    id: string,
    formData: any,
    headers: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/${familyMemberHistory}/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot Update Family History Record",
        };
      }
      console.error(
        `Error updating family member information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async saveFamilyHistory(formData: any, headers: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/${familyMemberHistory}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Create Family History",
        };
      }
      console.error(`Error saving family history. Error: ${JSON.stringify(e)}`);
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findActiveAlerts(subjectReference: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${medicalApiPaths.flag}/active/by-subject`,
      );
      url.searchParams.append("subject", subjectReference);
      const response = await axios.get(url.toString(), config);
      return response?.data || [];
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error(
        `Error when retrieving active alerts for ${subjectReference} ` +
          JSON.stringify(e),
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findConditions(query: any) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(`${apiConfig.medicalsServiceBase}/${conditions}`);
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error("Error when retrieving conditions. " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findMedicationRequests(
    query: any,
    signal?: AbortSignal,
  ): Promise<MedicationRequest[]> {
    try {
      const config = await getAuthHeader(this.auth0Client);
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/medication-requests`,
      );

      if (signal) {
        config.signal = signal;
      }

      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error(
        "Error when retrieving medication requests. " + JSON.stringify(e),
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findMedicationAdministrations(
    query: any,
  ): Promise<MedicationAdministration[]> {
    try {
      const config = await getAuthHeader(this.auth0Client);
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/medication-administrations`,
      );
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error("Error when retrieving conditions. " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findMedicationStatements(query: any): Promise<MedicationStatement[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${medicationStatements}`,
      );
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        //return status code for notification
        return [];
      }
      console.error("Error when retrieving conditions. " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findMedicationStatementsHistory(query: {
    encounter: string;
    patient: string;
    status: string | string[];
    date: Date;
    limit: number;
  }) {
    try {
      const config = await getAuthHeader(this.auth0Client);
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${medicationStatements}/history`,
      );

      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        //return status code for notification
        return [];
      }
      console.error("Error when retrieving conditions. " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getEntityById(
    id: string,
    type: TMedicalApiPaths,
    query?: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: query,
      };

      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/${type}/${id}`,
        config,
      );

      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving ${type} with id: ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getObservationsByPatientId(
    id: string,
    codes?: string[],
    start?: string,
    end?: string,
    additionalParams?: Record<string, unknown>,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: {
          patient: id,
          limit: 50,
          code: codes,
          start: start,
          end: end,
          ...additionalParams,
        },
      };

      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/observations`,
        config,
      );

      return response.data;
    } catch (err) {
      if (err?.response?.data?.statusCode === 403) {
        return {};
      }

      console.error(
        `Error when retrieving vitals with patient id: ${id}. Error: ${JSON.stringify(
          err,
        )}`,
      );

      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getVitalsByObservationId(id: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/observations/${id}/vitals`,
        config,
      );
      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving vitals with observation id: ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }
  async getEncounterStatusTally(organizationId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/encounters/stats/by-status/${organizationId}`,
        config,
      );
      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving Encounter Tally by status for ${organizationId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findQuestionnaireResponse(
    code: string,
    searchParams: { [key: string]: any },
    signal?: AbortSignal,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: AxiosRequestConfig = {
        headers: { Authorization: `Bearer ${authToken}` },
      };

      if (signal) {
        config.signal = signal;
      }

      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/questionnaire-response`,
        {
          ...config,
          params: { code, ...searchParams },
        },
      );

      return response.data ?? [];
    } catch (error) {
      if (error?.response?.data?.statusCode === 403) {
        return {};
      }

      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findQuestionnaireResponseForPatient(
    patientId: string,
    questionnaire: string,
    encounterId?: string,
  ) {
    try {
      let response;

      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };

      if (!encounterId) {
        response = await axios.get(
          `${apiConfig.medicalsServiceBase}/questionnaire-response/patient/${patientId}/questionnaire/${questionnaire}`,
          config,
        );
      } else {
        response = await axios.get(
          `${apiConfig.medicalsServiceBase}/questionnaire-response/patient/${patientId}/encounter/${encounterId}/questionnaire/${questionnaire}`,
          config,
        );
      }

      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving questionnaire with patientID: ${patientId} and questionnaire code ${questionnaire}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findEntities(query: any, type: TMedicalApiPaths, signal?: AbortSignal) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(`${apiConfig.medicalsServiceBase}/${type}`);

      if (signal) {
        config.signal = signal;
      }

      return await querySearchEndpoint(url, query, config);
    } catch (e: any) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      Sentry.captureException(e);
      console.error("Error when retrieving compositions: ", JSON.stringify(e));

      throw new Error(`An error occurred while retrieving entities of type ${type}. Please try again.`);
    }
  }

  async countEntities(
    query: any,
    type: TMedicalApiPaths,
    signal?: AbortSignal,
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(
        `${apiConfig.medicalsServiceBase}/${type}/stats/count`,
      );

      if (signal) {
        config.signal = signal;
      }

      const response = querySearchEndpoint(url, query, config);
      return response;
    } catch (e) {
      console.error(
        "Error when retrieving total number of appointments " +
          JSON.stringify(e),
      );
      throw e;
    }
  }

  async setPatientAllergies(
    allergies: CodeableConcept[],
    patient: string,
    encounter: string,
    recorder: any,
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };
    const response = await axios.post(
      `${apiConfig.medicalsServiceBase}/${medicalApiPaths.allergies}/batch-set`,
      {
        allergies,
        patient,
        encounter,
        recorder,
      },
      config,
    );

    if (!response.data) throw new Error("An error occured");
    if (response.data.statusCode === 403) {
      throw {
        status: 403,
        message: `Insufficient Permissions - Cannot set patient allergies`,
      };
    }

    return response.data;
  }

  async setPatientMedications(
    medications: CodeableConcept[],
    patient: string,
    encounter: string,
    recorder: any,
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };
    const response = await axios.post(
      `${apiConfig.medicalsServiceBase}/${medicalApiPaths.medicationStatement}/batch-set`,
      {
        medications,
        patient,
        encounter,
        recorder,
      },
      config,
    );

    if (!response.data) throw new Error("An error occured");
    if (response.data.statusCode === 403) {
      throw {
        status: 403,
        message: `Insufficient Permissions - Cannot set patient allergies`,
      };
    }

    return response.data;
  }

  async setPatientMedicalHistory(
    conditions: any[],
    patient: string,
    encounter: string,
    recorder: any,
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };
    const response = await axios.post(
      `${apiConfig.medicalsServiceBase}/${medicalApiPaths.conditions}/batch-set`,
      {
        conditions,
        patient,
        encounter,
        recorder,
      },
      config,
    );

    if (!response.data) throw new Error("An error occured");
    if (response.data.statusCode === 403) {
      throw {
        status: 403,
        message: `Insufficient Permissions - Cannot set patient allergies`,
      };
    }

    return response.data;
  }

  async updateEntity(
    id: string,
    formData: any,
    type: TMedicalApiPaths,
    headers?: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };

      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/${type}/${id}`,
        formData,
        config,
      );
      if (!response.data) throw new Error("An error occured");

      return response.data;
    } catch (e) {
      Sentry.captureException(e);

      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: `Insufficient Permissions - Cannot update ${startCase(
            type,
          )}`,
        };
      }

      console.error(
        `Error updating ${startCase(
          type,
        )} with ID ${id}. Error: ${JSON.stringify(e)}`,
      );

      throw new Error(`An error occured. Please try again.`);
    }
  }

  async createEntity(
    formData: any,
    type: TMedicalApiPaths,
    headers?: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/${type}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      Sentry.captureException(e);

      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: `Insufficient Permissions - Cannot create new ${startCase(
            type,
          )}`,
        };
      }
      console.error(
        `Error updating ${startCase(type)}. Error: ${JSON.stringify(e)}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getConditionById(id: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/${conditions}/${id}`,
        config,
      );
      return response.data;
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return {};
      }
      console.error(
        `Error when retrieving condition with id: ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async saveCondition(formData: any, headers: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/${conditions}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Create New Condition",
        };
      }
      console.error("Error when adding new conditon data " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateCondition(
    id: string,
    formData: any,
    headers: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/${conditions}/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Update Condition",
        };
      }
      console.error(
        `Error updating condition information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findAllergies(query: any, signal?: AbortSignal): Promise<Allergy[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        signal,
      };
      const url = new URL(`${apiConfig.medicalsServiceBase}/${allergies}`);
      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error("Error when retrieving allergies " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async saveAllergy(formData: any, headers: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/${allergies}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot Create New Allergy Record",
        };
      }
      console.error("Error when adding new allergy data " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateAllergy(
    id: string,
    formData: any,
    headers: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/${allergies}/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Update Allergy Record",
        };
      }
      console.error(
        `Error updating allergy information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getEncounteryId(encounterId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/${encounters}/${encounterId}`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error when retrieving encounter with id: ${encounterId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      if (e.response?.data?.statusCode === 403) {
        throw new Error(
          "Access Denied: You do not have enough permissions to view the this item.",
        );
      }

      throw new Error(`An error occured. Please try again.`);
    }
  }

  async findEncounters(
    query: any,
    signal?: AbortSignal,
  ): Promise<MedicalEncounter[]> {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config: Record<string, unknown> = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const url = new URL(`${apiConfig.medicalsServiceBase}/encounters`);

      if (signal) {
        config.signal = signal;
      }

      return await querySearchEndpoint(url, query, config);
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        return [];
      }
      console.error("Error when retrieving encounters " + JSON.stringify(e));
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async saveEncounter(
    formData: any,
    headers: { [key: string]: any },
    files?: File[],
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "multipart/form-data",
          ...headers,
        },
      };

      const bodyFormData = createFormData(formData, files);

      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/encounters`,
        bodyFormData,
        config,
      );

      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e.response.status === 409) {
        // This indicates an encounter already existing
        const data = e.response.data;
        const encounter = data.encounter;

        throw {
          status: 409,
          message: "There is an ongoing encounter for this patient",
          encounter,
        };
      }

      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Create New Encounter",
        };
      }

      console.error(
        "Error when adding new encounter data " + JSON.stringify(e),
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateEncounter(
    id: string,
    formData: any,
    headers?: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/encounters/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message: "Insufficient Permissions - Cannot Update Encounter",
        };
      }
      console.error(
        `Error updating encounter information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async getEncounterCarePlan(encounterId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/${medicalApiPaths.carePlan}/encounter/${encounterId}`,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot revrieve careplan for this encounter",
        };
      }
      console.error(
        `Error revrieveng care plan for encounter with id ${encounterId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async updateEncounterProperties(
    id: string,
    formData: any,
    headers: { [key: string]: any },
  ) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };
      const response = await axios.put(
        `${apiConfig.medicalsServiceBase}/encounters/partial/${id}`,
        formData,
        config,
      );
      if (response.data) {
        return response.data;
      }
    } catch (e) {
      if (e?.response?.data?.statusCode === 403) {
        throw {
          status: 403,
          message:
            "Insufficient Permissions - Cannot Update Encounter Properties",
        };
      }
      console.error(
        `Error updating encounter information with ID ${id}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async finalizeEncounter(id: string, headers: { [key: string]: any }) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${authToken}`, ...headers },
      };

      const response = await axios.post(
        `${apiConfig.medicalsServiceBase}/encounters/finalize/${id}`,
        {},
        config,
      );

      if (response.data) {
        return response.data;
      }
    } catch (e) {
      console.error(
        `Error finalizing encounter with ID ${id}. Error: ${JSON.stringify(e)}`,
      );
      throw new Error(`An error occured. Please try again.`);
    }
  }

  async printEntities(
    ids: string[],
    organizationId: string,
    authId: string,
    type: TMedicalApiPaths,
    img?: string,
    options?: {
      responseType?: string;
      method?: "window" | "download";
    },
  ) {
    // set option defaults
    options = options ?? {
      responseType: "pdf",
      method: "window",
    };
    options.method = options.method ?? "window";
    options.responseType = options.responseType ?? "pdf";

    const url = new URL(`${apiConfig.medicalsServiceBase}/${type}/print`);

    if (organizationId) url.searchParams.append("o", organizationId);
    if (img) url.searchParams.append("img", img);
    if (options.responseType) {
      url.searchParams.append("response-type", options?.responseType);
    }

    for (const id of ids) {
      url.searchParams.append("requests", id);
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${await this.auth0Client?.getTokenSilently()}`,
        "x-organization-authid": authId,
      },
    });

    if (
      options.responseType === "pdf" &&
      response.headers.get("content-type") !== "application/pdf"
    ) {
      throw new Error("PDF file(s) could not be retrieved");
    }

    const blob = await response.blob();
    const file = window.URL.createObjectURL(blob);
    window.open(file, "_blank");
  }

  async findFirstEncounterForPatient(
    patientId: string,
    organizationId: string,
  ) {
    const authToken = await this.auth0Client.getTokenSilently();
    const config = { headers: { Authorization: `Bearer ${authToken}` } };
    const response = await axios.get(
      `${apiConfig.medicalsServiceBase}/encounters/patients/${patientId}/organizations/${organizationId}/first`,
      config,
    );

    return response.data;
  }

  async getCopilotForEncounter(encounterId: string) {
    try {
      const authToken = await this.auth0Client.getTokenSilently();
      const config = { headers: { Authorization: `Bearer ${authToken}` } };
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/copilots/by-encounter/${encounterId}`,
        config,
      );
      if (response.data) {
        return response.data;
      }
      return undefined;
    } catch (e) {
      console.error(
        `Error when retrieving copilot for encounter with ID: ${encounterId}. Error: ${JSON.stringify(
          e,
        )}`,
      );
      if (e.response?.data?.statusCode === 403) {
        throw new Error(
          "Access Denied: You do not have enough permissions to view the this item.",
        );
      }

      throw new Error(`An error occured. Please try again.`);
    }
  }

  // healthCheck
  async healthCheck() {
    try {
      const response = await axios.get(
        `${apiConfig.medicalsServiceBase}/health`,
      );
      return response.data;
    } catch (e) {
      console.error(
        "Medicals Service health check was unsuccessful" + JSON.stringify(e),
      );
    }
  }

  //medical configuration
  async getMedicalConfigByOrganization(orgId: string) {
    try {
      const response = await this.findEntities(
        { organization: orgId },
        medicalApiPaths.medicalConfigurations,
      );
      if (response?.[0]?.id) {
        return response[0];
      } else {
        //take the first from the list of find entities
        const config: MedicalConfiguration = {
          defaultEncounterType: "full",
          vitalMeasurement: {
            height: "cm",
            weight: "lbs",
            temperature: "celcius",
          },
        };
        return config;
      }
    } catch (e) {
      console.error("Failed to retrieve medical configs", e);
      throw e;
    }
  }
}

export const medicalsApi = new MedicalsApi();
