import { TNavItem } from "../types/navItem";
import { getOrganizationTier } from "./DataUtils";

function rolesMatch(allowedRoles: string[], userRoles: string[]): boolean {
  if (allowedRoles) {
    return allowedRoles.some((role) => userRoles?.includes(role));
  }
  return true;
}

export function shouldRenderMenuItem(
  item: TNavItem,
  launchDarklyFlags,
  adminSettings,
  orgId: string,
  userRoles: string[],
) {
  const FORCE_MENU_ITEMS = import.meta.env.VITE_FORCE_MENU_ITEMS;
  const { ldFlag, tier, ldOrgFlag, allowedRoles } = item;
  const organizationTier = getOrganizationTier(adminSettings);

  if (FORCE_MENU_ITEMS === "true" || (!ldFlag && !tier && !ldOrgFlag)) {
    return true;
  }

  if (ldFlag) {
    if (tier && !ldOrgFlag) {
      return (
        launchDarklyFlags[ldFlag] &&
        tier?.includes(organizationTier) &&
        rolesMatch(allowedRoles, userRoles)
      );
    }

    if (tier && ldOrgFlag) {
      return (
        launchDarklyFlags[ldFlag] &&
        tier?.includes(organizationTier) &&
        launchDarklyFlags[ldOrgFlag]?.includes(orgId) &&
        rolesMatch(allowedRoles, userRoles)
      );
    }

    return launchDarklyFlags[ldFlag] && rolesMatch(allowedRoles, userRoles);
  }

  if (tier && ldOrgFlag) {
    return (
      tier?.includes(organizationTier) &&
      launchDarklyFlags[ldOrgFlag]?.includes(orgId) &&
      rolesMatch(allowedRoles, userRoles)
    );
  }

  if (tier) {
    return (
      tier?.includes(organizationTier) && rolesMatch(allowedRoles, userRoles)
    );
  }

  if (ldOrgFlag) {
    return (
      launchDarklyFlags[ldOrgFlag]?.includes(orgId) &&
      rolesMatch(allowedRoles, userRoles)
    );
  }

  return false;
}
